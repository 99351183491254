
import { defineComponent, reactive, toRefs, ref } from "vue";
import { Store } from "vuex";
import store from "@/store/index";
import router from "@/router/index";
import { ElLoading, ElMessage } from 'element-plus'
import { apiLogin, apiSendCode } from '@/util/apis'
export default defineComponent({
  name: "",
  setup: () => {
    var passwordreg = /^(((13[0-9]{1})|(15[0-9]{1})|(16[0-9]{1})|(17[3-8]{1})|(18[0-9]{1})|(19[0-9]{1})|(14[5-7]{1}))+\d{8})$/;
    const login = () => {
      const $store: Store<any> = store;
      $store.dispatch("GET_ROUTERS_DATA");
    };
    const state = reactive({
      loginRuleFrom: {
        username: "",
        password: "",
        phone: '',
        phonecode: ''
      },
      loginrules: {
        username: [{ required: true, message: '用户名/手机号未填写', trigger: 'blur' }],
        password: [{ required: true, message: '密码未填写', trigger: 'blur' }],
        phone: [{ required: true, message: '手机号未填写', trigger: 'blur' }, {
          trigger: 'blur',
          validator: (rule: any, value: any, callback: any) => {
            var passwordreg = /^(((13[0-9]{1})|(15[0-9]{1})|(16[0-9]{1})|(17[3-8]{1})|(18[0-9]{1})|(19[0-9]{1})|(14[5-7]{1}))+\d{8})$/;
            !passwordreg.test(value) ? callback(new Error('请输入正确的手机号码哦!')) : callback();
          }
        }],
        phonecode: [{ required: true, message: '短信验证码未填写', trigger: 'blur' }]
      },

      activeName: 'first',
      isDisposed: false,
      time: 60,
      phoneErr: '',

    });
    const ruleFormRef = ref(null);
    const ruleFormRefs = ref(null);

    const submitForm = async (formEl: any) => {
      if (!formEl) return
      await formEl.validate((valid: any, fields: any) => {
        if (valid) {
          const $store: Store<any> = store;
          var loginParam = state.activeName == 'first' ? {
            mode: 'phone',
            mobile: state.loginRuleFrom.phone,
            checkCode: state.loginRuleFrom.phonecode
          } : {
            mode: 'password',
            username: state.loginRuleFrom.username,
            password: state.loginRuleFrom.password
          }
          apiLogin(loginParam).then(res => {
            console.log('res==', res)
            if (res.code == 0 && res.data.token) {
              $store.dispatch("GET_ROUTERS_DATA");
              localStorage.setItem(
                "v3-element-plus-token",
                JSON.stringify(res.data.token)
              );
            } else if (res.code == 90000 && res.msg) {
              ElMessage.error(res.msg)
            }
          })
        }
      })
    }

    const resetForm = (formEl: any) => {
      if (!formEl) return
      formEl.resetFields()
    }

    const getCode = () => {
      if (!state.loginRuleFrom.phone) {
        state.phoneErr = '手机号未填写';
        return
      }
      if (!passwordreg.test(state.loginRuleFrom.phone)) {
        state.phoneErr = '请输入正确的手机号码哦!';
        return
      }
      if (!state.isDisposed) {
        state.isDisposed = true;
        apiSendCode({
          mobile: state.loginRuleFrom.phone
        }).then(res => {
          handleTimeChange();
          ElMessage({
            showClose: true,
            message: '发送成功',
            type: 'success',
          })
        }).catch(f => {
          state.isDisposed = false;
        })

      }
    }

    const handleTimeChange = () => {
      if (state.time <= 0) {
        state.isDisposed = false;
        state.time = 60;
      } else {
        setTimeout(() => {
          state.time--;
          handleTimeChange();
        }, 1000);
      }
    }

    const goRegister = () => {
      router.push({ path: "/register" });
    }
    return {
      login,
      ...toRefs(state), submitForm, resetForm, ruleFormRef, ruleFormRefs, getCode, goRegister
    };
  },
});
